<template>
    <div class="container-fluid">
        <!-- <div class="row opacity-100"><img alt="Jet Dock Wisconsin"  class="img-fluid p-2" :src="img1"></div> -->
        <div class="row bg-info text-start">
          <div class="col"></div>
          <div class="mx-auto">
            <!-- <table class="table table-striped table-hover table-sm caption-top text-white">
                    <caption class="caption text-center text-white">Wisconsin State Fair Expo Boat Show Hours Booth #254</caption>
                    <thead>  
                  <tr>
                      <th>Day</th>
                      <th>Date</th>
                      <th>Hours</th>
                  </tr>
                    </thead>
                    <tbody>
                  <tr v-for="item in boatshowhours" v-bind:key="item.index">
                          <td>{{item.Day}}</td>
                          <td>{{item.Date}}</td>
                          <td>{{item.Time}}</td>
                    </tr>
                    </tbody>
              </table> -->
            <div class="col"></div>
          </div>
          <p class="text-white">
            Jet Dock systems make boating easier and more enjoyable with their ease of customization and operation. 
            A Jet Dock solution has many advantages over other lift options including their versatility, durability, boat accommodation, and safety. 
            Spend less time turning cranks and more time on the water with our simple and safe drive-on lift system. 
            Jet Dock owners estimate that they find themselves being able to use their watercraft up to 3 times more after purchase 
            due to the ease of use and ownership of our drive-on lift systems and floating dock solutions.
          </p>
        </div>
    <cform3/>
    </div>
</template>

<script>
import cform3 from './cform3.vue'
import axios from "axios"
export default {
  name: "JetDockMainPage",
  data (){
      return {
          img1: "https://dsm01pap006files.storage.live.com/y4mbx8_3xkbG2XL3D4AI0kMymkJ5rk48Q26cOVOpETMzZHZCZtUiy8py-lEsFWURnPeKmvfqJudJsurUk8Sce2vJEcleUpihCPbQJj4X-g9_flTRBcnVuTp0V8QaBYzEj1cflMoMAb5XtcN4fUGkLuZGf8XNpE-Lcc5IBvq0ZEC6tXm7EbdqYcVspA1uk8DEYuQ?width=1432&height=358&cropmode=none",
          boatshowhours: null
        }
  },
  methods: {
    gd: function(){
    axios.get('https://opensheet.elk.sh/1WszXNeNd17wwtwZJWwAZ2RatTCa1n9Sd4HuOSXH8q6M/sheet1')
    .then(response => response.data)
    .then(data=>{
      this.boatshowhours = data
      // const products = data
      // this.filterdata = products.filter(product => product.includes('currant'))
      console.log(data)
    })
  }
},
  created(){
    this.gd()
  },
  mounted(){
    this.interval = setInterval(() => {
      this.gd()
    }, 30000);
  },
  components: {
    cform3
  }
};
</script>